import { Locale } from '@/src/modules/i18n';

export const isMocks =
	process.env.USE_MOCKS === 'true' || process.env.NEXT_PUBLIC_USE_MOCKS === 'true';
export const release = `org-jugru v.${(
	process.env.RELEASE ??
	process.env.NEXT_PUBLIC_RELEASE ??
	'0.0.1'
).replace('/', '_')}`;
export const serverToken = process.env.SERVER_TOKEN;
export const debugMode = process.env.DEBUG_MODE === 'true';

export type StageType = 'local' | 'development' | 'testing' | 'production';

const environments: Record<string, StageType> = {
	local: 'local',
	dev: 'development',
	test: 'testing',
	prod: 'production',
};

const STAGE = 'dev';

// it's necessary so that the bash script does not correct this line
const STAGE_FOR_LOCAL_USAGE = 'STAGE' + '_NAME' + '_FROM' + '_NODE' + '_STAGE';

export const isLocalEnvironment = STAGE === STAGE_FOR_LOCAL_USAGE;

export const getEnvironment = () => {
	if (isLocalEnvironment) {
		return environments.local;
	}

	return environments[STAGE] ?? environments.dev;
};

export const isDevelopmentEnvironment = getEnvironment() === 'development';
export const isTestingEnvironment = getEnvironment() === 'testing';
export const isProductionEnvironment = getEnvironment() === 'production';
export const isDevelopmentOrLocalEnvironment = isDevelopmentEnvironment || isLocalEnvironment;
// export const isTestingOrProductionEnvironment = isTestingEnvironment || isProductionEnvironment;

export const getBaseApiURL = () => {
	return {
		// local: 'https://org-dev.jugru.org/api',
		local: 'http://localhost:3010/api',
		development: 'https://org-dev.jugru.org/api',
		testing: 'https://org-test.jugru.org/api',
		production: 'https://org.jugru.org/api',
	}[getEnvironment()];
};

export const getSpeakersHostUrl = () => {
	return {
		local: 'https://speakers-dev.jugru.org/',
		development: 'https://speakers-dev.jugru.org/',
		testing: 'https://speakers-test.jugru.org/',
		production: 'https://speakers.jugru.org/',
	}[getEnvironment()];
};

export const getHostUrl = () => {
	return {
		local: 'https://org-dev.jugru.org/',
		development: 'https://org-dev.jugru.org/',
		testing: 'https://org-test.jugru.org/',
		production: 'https://org.jugru.org/',
	}[getEnvironment()];
};

export const getServerSideHostUrl = () => {
	return {
		local: 'https://speakers-dev.jugru.org/',
		development:
			'http://development-srm-backend-service-v2.srm-backend-260-development.svc.cluster.local:8080/',
		testing:
			'http://testing-srm-backend-service-v2.srm-backend-260-testing.svc.cluster.local:8080/',
		production:
			'http://production-srm-backend-service-v2.srm-backend-260-production.svc.cluster.local:8080/',
	}[getEnvironment()];
};

export const getLKUrl = () => {
	return {
		local: `https://lk-dev.jugru.org`,
		development: `https://lk-dev.jugru.org`,
		testing: `https://lk-test.jugru.org`,
		production: `https://my.jugru.org`,
	}[getEnvironment()];
};

export const getSpeakerRoomUrl = () => {
	return {
		local: `https://development.speakerroom.club`,
		development: `https://development.speakerroom.club`,
		testing: `https://testing.speakerroom.club`,
		production: `https://speakerroom.com`,
	}[getEnvironment()];
};

export const getRingoStarUrl = () => {
	return {
		local: 'https://dev-ringostar.jugru.team/api/v2/collector/collect',
		development: 'https://dev-ringostar.jugru.team/api/v2/collector/collect',
		testing: 'https://test-ringostar.jugru.team/api/v2/collector/collect',
		production: 'https://ringostar.jugru.team/api/v2/collector/collect',
	}[getEnvironment()];
};

export const getCfpUrl = (locale: Locale) => {
	const path = locale === 'ru' ? '' : '/en';
	return {
		local: `https://jugru.org${path}/callforpapers-speaker/`,
		development: `https://jugru.org${path}/callforpapers-speaker/`,
		testing: `https://jugru.org${path}/callforpapers-speaker/`,
		production: `https://jugru.org${path}/callforpapers-speaker/`,
	}[getEnvironment()];
};

export const baseApiPath = 'api/v1';
const baseMockApiPath = 'api/mock/v1';

export const getBaseUrl = (
	serverSide = false,
	ignoreMocks = false,
	baseApiPathOverride?: string,
) => {
	if (isMocks && isLocalEnvironment && !ignoreMocks) {
		return `/${baseMockApiPath}`;
	}

	if (isMocks && isDevelopmentEnvironment && !ignoreMocks) {
		return `${serverSide ? getServerSideHostUrl() : getHostUrl()}${baseMockApiPath}`;
	}

	return `${serverSide ? getServerSideHostUrl() : getHostUrl()}${
		baseApiPathOverride ?? baseApiPath
	}`;
};

export const getSquidexUrl = () => {
	return {
		local: `https://squidex-dev.jugru.team`,
		development: `https://squidex-dev.jugru.team`,
		testing: `https://squidex-test.jugru.team`,
		production: `https://squidex.jugru.team`,
	}[getEnvironment()];
};

export const getJiraUrl = () => {
	return {
		local: `https://dev-jira.jugru.org`,
		development: `https://dev-jira.jugru.org`,
		testing: `https://dev-jira.jugru.org`,
		production: `https://jira.jugru.org`,
	}[getEnvironment()];
};

export const servicesUrl = () => {
	return {
		local: 'https://services-dev.jugru.team',
		development: 'https://services-dev.jugru.team',
		testing: 'https://services-test.jugru.team',
		production: 'https://services.jugru.team',
	}[getEnvironment()];
};

export const liveProxiedBasePath = `${getBaseUrl()}/proxy/online/api/v2`;

export const mdmServiceBasePath = `${servicesUrl()}/analytics-data/mdm-analytics-data-proxy/api/v1`;
export const mdmServiceProxiedBasePath = `${getBaseUrl()}/proxy/mdm/api/v1`;

export const jpaServiceProxiedBasePath= `${getBaseUrl()}/proxy/jpa/restws/v1`;
