import {
	Authorities,
	createJWT,
	createJWTFromObject,
	deleteCookie,
	setCookie,
} from '@/src/modules/common/utils';

export const ONLINE_SESSION_ACCESS_TOKEN_KEY = 'online.session.access_token';
export const ONLINE_SIGN_KEY = 'online.session.sign';

export const getAccessTokenFixtureFromLocalStorage = (): string => {
	return typeof window !== 'undefined'
		? (localStorage.getItem(ONLINE_SESSION_ACCESS_TOKEN_KEY) ?? '')
		: '';
};

export const getAccessTokenFixtureFromCookies = (): string => {
	if (typeof window === 'undefined') {
		return '';
	}

	if (!document.cookie) {
		return '';
	}

	let token: string | undefined;
	let sign: string | undefined;

	const cookies = document.cookie.split('; ');

	cookies.forEach((cookie) => {
		if (cookie.startsWith(`${ONLINE_SESSION_ACCESS_TOKEN_KEY}=`)) {
			token = cookie.split('=')[1];
		}

		if (cookie.startsWith(`${ONLINE_SIGN_KEY}=`)) {
			sign = cookie.split('=')[1];
		}
	});

	if (!token || !sign) {
		return '';
	}

	return `${token}.${sign}`;
};

export const removeAccessTokenFixtureFromCookies = () => {
	deleteCookie(ONLINE_SESSION_ACCESS_TOKEN_KEY);
	deleteCookie(ONLINE_SIGN_KEY);
};

export const setAccessTokenFixtureFromAuthoritiesToCookies = (authorities: Authorities) => {
	const token = createJWT(authorities);

	setCookie(ONLINE_SESSION_ACCESS_TOKEN_KEY, `${token.token}; Path=/;`);
	setCookie(ONLINE_SIGN_KEY, `${token.sign}; Path=/;`);
};

export const setAccessTokenFixtureFromObjectToCookies = (object: object) => {
	const token = createJWTFromObject(object);

	setCookie(ONLINE_SESSION_ACCESS_TOKEN_KEY, `${token.token}; Path=/;`);
	setCookie(ONLINE_SIGN_KEY, `${token.sign}; Path=/;`);
};
