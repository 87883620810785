export type LocaleDateOptions = {
	localeMatcher?: 'best fit' | 'lookup';
	weekday?: 'long' | 'short' | 'narrow';
	era?: 'long' | 'short' | 'narrow';
	year?: 'numeric' | '2-digit';
	month?: 'numeric' | '2-digit' | 'long' | 'short' | 'narrow';
	day?: 'numeric' | '2-digit';
	hour?: 'numeric' | '2-digit';
	minute?: 'numeric' | '2-digit';
	second?: 'numeric' | '2-digit';
	timeZoneName?: 'long' | 'short';
	formatMatcher?: 'best fit' | 'basic';
	hour12?: boolean;
	timeZone?: string;
};

export const countDaysBetweenDates = (date1: Date, date2: Date): number => {
	let diff = (date2.getTime() - date1.getTime()) / 1000;
	diff = diff / 60 / 60 / 24;
	return Math.abs(Math.round(diff));
};

export const getLocaleTimeZone = () => new Date().getTimezoneOffset() / -60;
export const getTimeZoneString = (tz: number) => {
	const UTC = 'UTC';

	return tz === 0 ? `${UTC} 0:00` : tz > 0 ? `${UTC}+${tz}:00` : `${UTC}${tz}:00`;
};
export const getLocaleTimeZoneString = () => getTimeZoneString(getLocaleTimeZone());

export const getPrimeReactRuLocale = () => ({
	firstDayOfWeek: 1,
	dayNames: ['Воскресенье', 'Понедельник', 'Вторник', 'Среда', 'Четверг', 'Пятница', 'Суббота'],
	dayNamesShort: ['ВС', 'ПН', 'ВТ', 'СР', 'ЧТ', 'ПТ', 'СБ'],
	dayNamesMin: ['ВС', 'ПН', 'ВТ', 'СР', 'ЧТ', 'ПТ', 'СБ'],
	monthNames: [
		'Январь',
		'Февраль',
		'Март',
		'Апрель',
		'Май',
		'Июнь',
		'Июль',
		'Август',
		'Сентябрь',
		'Октябрь',
		'Ноябрь',
		'Декабрь',
	],
	monthNamesShort: [
		'ЯНВ',
		'ФЕВ',
		'МАР',
		'АПР',
		'МАЙ',
		'ИЮН',
		'ИЮЛ',
		'АВГ',
		'СЕН',
		'ОКТ',
		'НОЯ',
		'ДЕК',
	],
	today: 'Сегодня',
	clear: 'Очистить',
});

export const localeDate = (
	date: Date | string,
	locale: string,
	options?: LocaleDateOptions,
): string => {
	const correctDate = new Date(date);

	try {
		return new Intl.DateTimeFormat(locale, { ...options, hour12: false }).format(correctDate);
	} catch {
		return '-';
	}
};
